import React from 'react';
import Contact from '../Contact';
import About from '../About';
import Hero from '../Hero';
import Projects from '../Projects';

const Home = () => {

    return (
        <>
            <Hero />
            <About />
            <Projects />
            <Contact />
        </>
    );
};

export default Home;


