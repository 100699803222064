import React from 'react';
import {
  Box,
  HStack,
  Flex,
  Link as ChakraLink,
  Button,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow="base"
        zIndex={999}
      >
        <Center py={5}>
          <Flex id="navbar" h={16} >
            <HStack spacing={200}>
              {/* Logo starts here */}
              <Box
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontSize="2xl"
                fontWeight="extrabold"
                fontFamily="sans-serif"
              >
                Aabishkar Karki
              </Box>
              {/* Logo ends here */}
              <HStack
                as={'nav'}
                spacing={9}
                display={{ base: 'none', md: 'flex' }}
              >
                <ChakraLink
                  px={2}
                  fontWeight={'bold'}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: "none",
                    bg: useColorModeValue("gray.300", "#FF3E54"),
                  }}
                  href={'#mainhero'}
                >
                  Home
                </ChakraLink>
                <ChakraLink
                  px={2}
                  fontWeight={'bold'}
                  spacing={4}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: "none",
                    bg: useColorModeValue("gray.200", "#FF3E54"),
                  }}
                  href={'#about'}
                >
                  About
                </ChakraLink>
                <ChakraLink
                  px={2}
                  spacing={4}
                  fontWeight={'bold'}
                  display={{ base: 'none', md: 'flex' }}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    textDecoration: "none",
                    bg: useColorModeValue("gray.200", "#FF3E54"),
                  }}
                  href={'#projects'}
                >
                  Projects
                </ChakraLink>
                <Button
                  as={Link}
                  rounded={"full"}
                  size={"lg"}
                  fontWeight={"bold"}
                  color="white"
                  px={6}
                  backgroundColor={useColorModeValue("#0E1F51", "#1a365d")}
                  _hover={{ bg: '#0E1F51' }}
                  onClick={() => {
                    window.location.href = '#contact';
                  }}
                >
                  Get in Touch
                </Button>
              </HStack>
            </HStack>
            <Flex
              ml={{ lg: 10, base: 5 }}
              mr={{ base: 5 }}
              alignItems={'center'}
            >
              <HStack>
                <Button onClick={toggleColorMode}>
                  {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                </Button>
                <IconButton
                  size={'lg'}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={'Open Menu'}
                  display={{ md: 'none' }}
                  onClick={isOpen ? onClose : onOpen}
                />
              </HStack>
            </Flex>
          </Flex>
        </Center>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }} mr={5} ml={'75%'}>
            <Stack as={'nav'} spacing={4}>
              <ChakraLink
                py={1}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                }}
                href="#about"
                onClick={isOpen ? onClose : onOpen}
              >
                Home
              </ChakraLink>
              <ChakraLink
                py={1}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                }}
                href="#services"
                onClick={isOpen ? onClose : onOpen}
              >
                About
              </ChakraLink>
              <ChakraLink
                py={1}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                }}
                href="#partners"
                onClick={isOpen ? onClose : onOpen}
              >
                Projects
              </ChakraLink>
              <ChakraLink
                py={1}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                }}
                href="#contact"
                onClick={isOpen ? onClose : onOpen}
              >
                Get In Touch
              </ChakraLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Navbar;
