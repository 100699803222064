import React from "react";
import {
  Container,
  Center,
  Stack,
  Heading,
  Text,
  Button,
  Image,
  Highlight,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,

} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import resume from "./image/CV.png";

const Hero = () => {
  return (
    <>
      <Container maxW={"8xl"} id="mainhero">
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Center>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                fontFamily={"sans-serif"}
              >
                <Text
                  as={"span"}
                  position={"relative"}
                  _after={{
                    content: "''",
                    width: "full",
                    height: "20%",
                    position: "absolute",
                    bottom: 2,
                    left: 0,
                    bg: "pink.400",
                    zIndex: -1,
                  }}
                >
                  Welcome to my
                </Text>
                <br />
                <Text
                  as={"span"}
                  color={"pink.400"}
                  bgGradient="linear(to-l, #7928CA, #FF0080)"
                  bgClip="text"
                >
                  Website
                </Text>
              </Heading>
            </Center>

            <Text color={useColorModeValue("black", "white")} textAlign="justify" p={5}>
              <Highlight
                query="PYTHON"
                styles={{
                  px: "2",
                  py: "0.3",
                  rounded: "full",
                  bg: "green.100",
                }}
              >
                Hello and Namaste! I am Aabishkar Karki a full-stack web developer passionate about technologies.
                My expertise lie in back-end programming but I have good knowledge and experience of frontend-end development as well.

                One of my favorite technologies to work with is Python because of its huge potential in all aspects of programming
                whether it be frontend, backend, data engineering or others.
              </Highlight>
              <br />
              <br />
              <Highlight
                query="Flutter"
                styles={{ px: "2", py: "0.3", rounded: "full", bg: "blue.100" }}
              >
                I am currently expanding my skillset to include Flutter for
                mobile development 📱.
              </Highlight>
            </Text>
            <Center>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={{ base: "column", sm: "row" }}
                px={5}
              >
                <Button
                  rounded={"full"}
                  size={"lg"}
                  fontWeight={"normal"}
                  px={6}
                  textColor={useColorModeValue("white", "white")}
                  bgColor={useColorModeValue("#0E1F51", "#1a365d")}
                  _hover={{ bgColor: "blue" }}
                  onClick={() => {
                    window.location.href = "#contact";
                  }}
                >
                  Hire me
                </Button>

                <Popover placement="bottom" isLazy>
                  <PopoverTrigger>
                    <Button
                      rounded={"full"}
                      rightIcon={<ChevronDownIcon />}
                      size={"lg"}
                      colorScheme={"red"}
                      fontWeight={"normal"}
                      textColor={useColorModeValue("white", "white")}
                      bgColor={useColorModeValue("#0E1F51", "#1a365d")}
                      _hover={{ bgColor: "blue" }}
                      w="fit-content"
                    >
                      My Resume
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent _focus={{ boxShadown: "#f4f4" }} ml={5}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader fontWeight="bold">
                      My Resume(Click on image to open)
                    </PopoverHeader>
                    <PopoverBody>
                      <Image
                        alt={"Hero Image"}
                        fit={"cover"}
                        align={"center"}
                        w={"100%"}
                        h={"100%"}
                        src={resume}
                        onClick={() => {
                          window.open(
                            "https://drive.google.com/file/d/1JEuTwNnZ8AnrHkrzUs-OGCXogIfZfd9-/view?usp=sharing",
                            "_blank"
                          );
                        }}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default Hero;


