import React from "react";
// import { useDispatch } from "react-redux";
import {
  Button,
  Center,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import { projects } from "./constants";

import { BsGithub } from "react-icons/bs";
import styled from "styled-components";

const Projects = () => {
  const TagList = styled.ul`
    display: flex;
    padding-left: 10px;
  `;
  const Tag = styled.li`
    padding-left: 12px;
    letter-spacing: 0.1rem;
    list-style: none;
    font-size: 0.7rem;
  `;
  const TitleContent = styled.div`
    text-align: center;
    padding: 0.7rem 10px 0 0.7rem;
    margin: 0 15px 0 15px;
    padding-right: 50%;
    width: 100%;
  `;

  return (
    <>
      <Container maxW={"8xl"}>
        <Center id="projects" py={4}>
          <Text
            textTransform={"uppercase"}
            color={"#FF3E54"}
            mt={'15px'}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("red.100", "red.100")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            My Projects
          </Text>
        </Center>
        <Center py={2}>
          <Heading style={{ paddingLeft: 30 }}>
            <Text color={useColorModeValue("#0E1F51", "white")}>Some of the projects I have done</Text>
          </Heading>
        </Center>

        <section className="main" id="about">
          {projects.map(
            ({ image, title, description, tags, source, visit }) => (
              <div className="reveal card">
                <img src={image} alt={title} />
                <div className="card-body">
                  <h6>{title}</h6>
                  <p>{description}</p>
                  <TitleContent sx={{ textAlign: "center" }}><strong>Stack</strong></TitleContent>
                  <TagList sx={{ textAlign: "center" }}>
                    {tags.map((tag, index) => (
                      <Text style={{ fontWeight: "600" }}>
                        <Tag key={index}>{tag}</Tag>
                      </Text>
                    ))}
                  </TagList>
                  <div>
                    <Stack
                      width={"100%"}
                      mt={"1rem"}
                      direction={"row"}
                      padding={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {visit ? <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        _focus={{
                          bg: "gray.200",
                        }}
                        onClick={() => {
                          window.open(visit, "_blank");
                        }}
                      >
                        Visit
                      </Button> : ''
                      }

                      {source ? <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        bg={"black"}
                        color={"white"}
                        boxShadow={
                          "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                        }
                        _hover={{
                          bgGradient: "linear(to-r, #7928CA, #FF0080)",
                        }}
                        onClick={() => {
                          window.open(source, "_blank");
                        }}
                      >
                        {<BsGithub size="28px" />}
                      </Button> : ''}


                    </Stack>
                  </div>
                </div>
              </div>
            )
          )}
        </section>
      </Container>

    </>
  );
};

export default Projects;
