export const projects = [
  {
    title: "Techore Solutions Website",
    description:
      "Designed and developed website for tech startup Techore Solutions.",
    image: "/images/techore.png",
    tags: ["React.Js", "Firebase", "Chakra-UI", "CSS"],
    visit: "https://techoresolutions.com.np",
    id: "techore-website",
  },
  {
    title: "REDA Website",
    description:
      "Designed, Developed and Hosted the website for REDA Palpa.",
    image: "/images/reda.png",
    tags: ["React.Js", "Chakra-UI", "Django", "Cpanel", "MySQL"],
    visit: "https://redapalpa.org.np",
    id: "reda-website",
  },
  {
    title: "Portfolio Website",
    description:
      "Portfolio Website for myself to showcase my skills and projects.",
    image: "/images/portfolio.png",
    tags: ["React JS", "ChakraUI", "Firebase"],
    visit: "https://karkiaabishkar.com.np",
    id: "portfolio-website",
  },
  {
    title: "Hotel Application",
    description:
      "Application where you can see all the hotels from an API and save your favourite ones.",
    image: "/images/github.png",
    tags: ["ReactJS", "TypeScript", "Django", "PostgreSQL"],
    source: "https://github.com/akarki7/hotel-application",
    id: "hotel-application",
  },
  {
    title: "Python Website Crawler",
    description:
      "Website crawler that extracts data from different websites and stores them.",
    image: "/images/github.png",
    tags: ["Python", "Scrapy", "Unittests", "Documentation"],
    source: "https://github.com/akarki7/Crawler",
    id: "website-crawler",
  },
  {
    title: "Messenger Application",
    description:
      "A Facebook-messenger type clone where users can chat in a chatroom real-time.",
    image: "/images/messenger.png",
    tags: ["React JS", "Firebase", "Firestore"],
    source: "https://messenger-clone-446ce.web.app/",
    visit: "https://github.com/akarki7/Messenger-Clone",
    id: "messenger-application",
  },
];
