import React from 'react';
import {
  Tooltip,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  useColorModeValue,
  Center,
  Box,
} from '@chakra-ui/react';
import { SiDjango, SiGitlab, SiFlutter, SiAmazonaws, SiTerraform, SiServerless, SiDocker, SiPostman } from 'react-icons/si';
import {
  IoLogoJavascript,
  IoLogoPython,
  IoLogoHtml5,
  IoLogoReact,
  IoLogoGithub,
  IoLogoCss3,
} from 'react-icons/io5';
import bannerIMG_2 from './image/Banner.png';

const Feature = ({ icon, iconBg, detail }) => {
  return (
    <Stack direction={'row'} align={'center'} mb={5}>
      <Tooltip hasArrow label={detail}>
        <Flex
          w={{ base: 8, xl: 10 }}
          h={10}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}
          _hover={{ transform: 'scale(1.1)' }}
        >
          {icon}
        </Flex>
      </Tooltip>
    </Stack>
  );
};

const About = () => {
  return (
    <Container bg={useColorModeValue("rgba(247,247,247,1)", "")} maxW="100%" >
      <Container id="about" maxW={'8xl'} py={12} isLazy>
        <Center mb={10}>
          <Text
            textTransform={'uppercase'}
            color={'#FF3E54'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('red.100', 'red.100')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
          >
            About me
          </Text>
        </Center>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            w={"full"}
            order={{ base: 1, md: 0 }}
          >
            <Box
              position={"relative"}
              height={"550px"}
              rounded={"2xl"}
              overflow={"hidden"}
            >
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                src={bannerIMG_2}
              />
            </Box>
          </Flex>
          <Stack spacing={4} order={{ base: 0, md: 1 }} >
            <Heading>
              <Text color={useColorModeValue('#0E1F51', 'white')}>
                Technology is best when it brings people together.
              </Text>
            </Heading>
            <Text color={useColorModeValue('#242424', 'white')} fontSize={'lg'} align={"justify"}>
              I am a highly skilled software developer with 2+ years of experience in full-stack development. I have
              a BSc in Computer Science from Jacobs University Bremen, Germany with a minor in Global Economics and
              Management.
              My expertise lies in developing and maintaining backend as well as web applications. I have experience
              deploying applications on the cloud using AWS, Terraform and Serverless for quick and efficient deployment.
              I have experience working in an agile environement in both Scrum and Kanban.
              <br></br>
              <br></br>
              I am passionate about creating user-friendly, high-performance, and scalable applications that meet the needs
              of businesses and end-users. My goal is to continue to improve my skills and stay up to date with the latest
              technologies to deliver the best possible solutions to my clients.
            </Text>
            <Text color={'#242424'} fontSize={'md'}>
              Some of the tech stacks I know are:
            </Text>
            <Flex
              display="grid"
              gridTemplateColumns={{ base: '1fr 1fr 1fr 1fr 0fr', sm: '1fr 1fr 1fr 1fr 0fr', md: '1fr 1fr 1fr 1fr 0fr', lg: '1fr 1fr 1fr 1fr 0fr', xl: '1fr 1fr 1fr 1fr 1fr 1fr 0fr' }}
              marginLeft={{ base: '0%', xl: "5%" }}
              mb={{ base: '5px', lg: '50px' }}
            >
              <Feature
                icon={
                  <Icon
                    as={IoLogoPython}
                    color={useColorModeValue('blue.200', 'blue.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Python"
              />

              <Feature
                icon={
                  <Icon
                    as={SiDjango}
                    color={useColorModeValue('blue.200', 'blue.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Django"
              />
              <Feature
                icon={
                  <Icon
                    as={IoLogoReact}
                    color={useColorModeValue('blue.200', 'blue.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="ReactJS"
              />
              <Feature
                icon={
                  <Icon
                    as={IoLogoHtml5}
                    color={useColorModeValue('red.300', 'red.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="HTML5"
              />
              <Feature
                icon={
                  <Icon
                    as={IoLogoJavascript}
                    color={useColorModeValue('yellow.300', 'yellow.400')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="JavaScript"
              />
              <Feature
                icon={
                  <Icon
                    as={IoLogoCss3}
                    color={useColorModeValue('blue.200', 'blue.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="CSS3"
              />
              <Feature
                icon={
                  <Icon
                    as={SiPostman}
                    color={useColorModeValue('orange.500', 'orange.600')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Postman"
              />
              <Feature
                icon={
                  <Icon
                    as={SiFlutter}
                    color={useColorModeValue('blue.200', 'blue.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Flutter"
              />
              <Feature
                icon={
                  <Icon
                    as={SiAmazonaws}
                    color={useColorModeValue('grey', 'red.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="AWS"
              />
              <Feature
                icon={
                  <Icon
                    as={SiTerraform}
                    color={useColorModeValue('purple.500', 'purple.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Terraform"
              />
              <Feature
                icon={
                  <Icon
                    as={SiServerless}
                    color={useColorModeValue('red.500', 'red.600')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Serverless"
              />
              <Feature
                icon={
                  <Icon
                    as={IoLogoGithub}
                    color={useColorModeValue('white', 'blue.700')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Github"
              />
              <Feature
                icon={
                  <Icon
                    as={SiGitlab}
                    color={useColorModeValue('orange', 'orange.500')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Gitlab"
              />
              <Feature
                icon={
                  <Icon
                    as={SiDocker}
                    color={useColorModeValue('blue.500', 'blue.600')}
                    w={7}
                    h={7}
                  />
                }
                iconBg={useColorModeValue('black', 'white')}
                detail="Docker"
              />
            </Flex>
          </Stack>
        </SimpleGrid>
      </Container >
    </Container>
  );
};

export default About;
