import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./styles.css";
import {
  useColorModeValue,
  Center,
  Stack,
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  FormControl,
  Input,
  Textarea,
  Link,
} from '@chakra-ui/react';
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
} from 'react-icons/md';
import { BsGithub, BsInstagram, BsLinkedin, BsFacebook } from 'react-icons/bs';
import './styles.css'


const Contact = () => {

  const [messageDisplay, setMessageDisplay] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m6zt11c', 'template_wmvad14', form.current, 'Sz3mWCgp4OFJmvOjp')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setMessageDisplay(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Container bg={useColorModeValue("rgba(247,247,247,1)", "")} maxW="100%" mt={5} >
      <Container id="contact" maxW="8xl" mt={0} overflow="hidden">
        <Center id="contact" py={4}>
          <Text
            textTransform={"uppercase"}
            color={"#FF3E54"}
            mt={'15px'}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("red.100", "red.100")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}
          >
            Get in Touch
          </Text>
        </Center>
        <Box
          m={{ sm: 5, md: 16, lg: 25 }}
          mb={'25px'}
        >
          <Stack spacing={0} align={'center'}>
            <Heading color={useColorModeValue("#0E1F51", "white")} ><Text>Hey! Let's Talk</Text></Heading>
          </Stack>
        </Box>
        <Flex
          display="grid"
          gridTemplateColumns={{ base: 'repeat(1, 2fr)', sm: 'repeat(1, 2fr)', md: 'repeat(1, 2fr)', lg: '1.5fr 1fr', xl: '1.5fr 1fr' }}
          mb={{ base: '5px', lg: '50px' }}
        >
          <Box
            bgColor={"white"}
            borderRadius="lg"
            p={{ sm: 5, md: 5, lg: 6 }}
            m={{ sm: 4, md: 16, lg: 25 }}
            mb={'15px'}
          >
            <Box p={2}>
              <Box my={4} textAlign="left" p={5}>
                <form ref={form} onSubmit={sendEmail}>
                  <FormControl>
                    <Input name="name" type="text" placeholder="Name" bg="#D3D3D3" _placeholder={{ color: '#545454' }} />
                  </FormControl>
                  <FormControl mt={6}>
                    <Input name="email" type="email" placeholder="Email" bg="#D3D3D3" _placeholder={{ color: '#545454' }} />
                  </FormControl>
                  <FormControl mt={6}>
                    <Input name="phone" type="tel" placeholder="Phone" bg="#D3D3D3" _placeholder={{ color: '#545454' }} />
                  </FormControl>
                  <FormControl mt={6}>
                    <Textarea name="message" bg="#D3D3D3" placeholder="Your Message" _placeholder={{ color: '#545454' }} />
                  </FormControl>
                  <Button width="full" mt={4} type="submit" bg="#004f9f" color={useColorModeValue("white", "white")} _hover={{ transform: 'scale(1.009)' }}>
                    Submit
                  </Button>
                </form>
                <Text id="success_message" mt={2} color={"green"} align="center" display={messageDisplay ? "block" : "none"}> Form has been submitted succesfully! I will get in touch with you soon.</Text>
              </Box>
            </Box>
          </Box>

          <Flex
            bgColor={"white"}
            borderRadius="lg"
            p={{ base: 5, sm: 5, md: 5, lg: 6, xl: 5 }}
            m={{ sm: 4, md: 16, lg: 25, xl: 25 }}
            mb={'15px'}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <VStack spacing={8} alignItems="left">
                <Center>
                  <Text color={"black"} fontWeight={"bold"} fontSize={"25"}>Contact info</Text>
                </Center>
                <HStack spacing={3}>
                  <MdPhone color="black" size="40px" />
                  <Link fontWeight={"bold"} color="black" href="tel:+4917649516192">+49 17649516192</Link>
                  <Text fontWeight={"bold"}>|</Text>
                  <Link fontWeight={"bold"} color="black" href="tel:+4917649516192"> +977 9843806963</Link>
                </HStack>
                <HStack spacing={3}>
                  <MdLocationOn color="red" size="40px" />
                  <Link href='https://goo.gl/maps/saD4QxRmY9GJxwHb7' fontWeight={"bold"} color="black"> College Ring 4, Bremen, Germany</Link>
                </HStack>
                <HStack spacing={3}>
                  <MdEmail color="#ffce00" size="40px" />
                  <Link fontWeight={"bold"} color="black" href="mailto:karkiaabishkar@gmail.com"> karkiaabishkar@gmail.com</Link>
                </HStack>
                <HStack
                  mt={{ lg: 5, md: 5 }}
                  spacing={5}>
                  <IconButton
                    aria-label="github"
                    color="black"
                    variant="ghost"
                    size="lg"
                    isRound={true}
                    _hover={{ transform: 'scale(1.1)' }}
                    icon={<BsGithub size="40px" />}
                    onClick={() => {
                      window.open("https://github.com/akarki7", "_blank");
                    }}
                  />
                  <IconButton
                    aria-label="linkedin"
                    color="#0072b1"
                    variant="ghost"
                    size="lg"
                    isRound={true}
                    _hover={{ transform: 'scale(1.1)' }}
                    icon={<BsLinkedin size="40px" />}
                    onClick={() => {
                      window.open("https://www.linkedin.com/in/aakarki/", "_blank");
                    }}
                  />
                  <IconButton
                    aria-label="facebook"
                    color="#1877F2"
                    variant="ghost"
                    size="lg"
                    isRound={true}
                    _hover={{ transform: 'scale(1.1)' }}
                    icon={<BsFacebook size="40px" />}
                    onClick={() => {
                      window.open("https://www.facebook.com/aabishkar.karki/", "_blank");
                    }}
                  />
                  <IconButton
                    aria-label="instagram"
                    color="red"
                    variant="ghost"
                    size="lg"
                    isRound={true}
                    _hover={{ transform: 'scale(1.1)' }}
                    icon={<BsInstagram size="40px" />}
                    onClick={() => {
                      window.open("https://www.instagram.com/karki_aabishkar/", "_blank");
                    }}
                  />
                </HStack>
              </VStack>
            </Box>
          </Flex>
        </Flex >
      </Container >
    </Container>

  );
}

export default Contact;
