import {
  Box,
  Text,
  useColorModeValue,
  Center,
} from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue('#0E1F51', '#0E1F51')}
      color={useColorModeValue('white', 'white')}
    >
      <Center
        maxW={'10xl'}
        py={5}
      >
        <Text fontSize={'sm'}>
          © 2023 Aabishkar Karki. All rights reserved{' '}
        </Text>
      </Center>
    </Box>
  );
}
